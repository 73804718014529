<script>
import TableView from '@/components/TableVIew'
import { Input, Button, Select, Popover } from 'ant-design-vue'
import FormUpload from '@/components/FormView/FormUpload'
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Object,
      default: () => ({ config: [] }),
    },
    code: {
      type: String,
      default: '',
    },
  },
  data() {
    return {}
  },
  mounted() {},
  methods: {
    getColumns() {
      return [
        {
          dataIndex: 'content',
          title: '分类名称',
          customRender: function (text, records, index, h) {
            return (
              <Input
                value={text}
                placeholder="请输入分类名称"
                onChange={(e) => {
                  records.content = e.target.value
                }}
              />
            )
          },
        },
        {
          display: ['custom_slider'].includes(this.code),
          dataIndex: 'sliderPreviewUrl',
          title: '预览图',
          customRender: (text, records, index, h) => {
            return (
              <FormUpload
                item={{}}
                style={{ width: '100px', height: '100px', transform: 'scale(0.7)' }}
                value={records.sliderPreviewUrl}
                onChange={(data) => {
                  records.sliderPreviewUrl = data
                }}
              />
            )
          },
        },
        {
          display: ['custom_classify'].includes(this.code),
          dataIndex: 'titleImg',
          title: '选中图',
          customRender: (text, records, index, h) => {
            return (
              <FormUpload
                item={{}}
                style={{ width: '100px', height: '100px', transform: 'scale(0.7)' }}
                value={records.titleImg}
                onChange={(data) => {
                  records.titleImg = data
                }}
              />
            )
          },
        },
        {
          display: ['custom_classify'].includes(this.code),
          dataIndex: 'unselectedTitleImg',
          title: '未选中图',
          customRender: function (text, records, index, h) {
            return (
              <FormUpload
                item={{}}
                style={{ width: '100px', height: '100px', transform: 'scale(0.7)' }}
                value={records.unselectedTitleImg}
                onChange={(data) => {
                  records.unselectedTitleImg = data
                }}
              />
            )
          },
        },
        {
          display: ['custom_slider'].includes(this.code),
          dataIndex: 'status',
          title: '更多跳转地址',
          width: 150,
          customRender: (text, records, index, h) => {
            return (
              <Select
                value={records.itemJumpConfigCode}
                style={{ width: '100%' }}
                onChange={(data) => {
                  records['itemJumpConfigCode'] = data
                }}
                getPopupContainer={(e) => {
                  return e.closest('table')
                }}
              >
                {(this.data.config || []).map((e) => {
                  return <Select.Option value={e.code}>{e.name}</Select.Option>
                })}
              </Select>
            )
          },
        },
        {
          dataIndex: 'sort',
          title: '优先级',
          customRender: function (text, records, index, h) {
            return (
              <Input
                value={text}
                placeholder="请输入优先级排序"
                onChange={(e) => {
                  records.sort = e.target.value
                }}
              />
            )
          },
        },
        {
          dataIndex: 'action',
          title: '操作',
          type: 'buttonGroup',
          align: 'center',
          typeData: ({ records, index }) => {
            return [
              {
                name: '商品列表',
                onClick: () => {
                  this.$emit('openTabsCommodity', records)
                },
              },
              {
                name: '添加商品',
                onClick: () => {
                  this.$emit('setGoods', records)
                },
              },
              {
                name: '删除',
                popTitle: '删除',
                type: 'pop',
                display: true,
                onClick: () => {
                  this.list.splice(index, 1)
                },
              },
            ].filter((e) => (e.display == undefined ? true : e.display))
          },
        },
      ].filter((e) => (e.display == undefined ? true : e.display))
    },
    sumbit() {
      this.$emit('success', this.$refs.table.selectedRows)
    },
  },
  render() {
    return (
      <div>
        <TableView ref={'table'} dataSource={this.list} columns={this.getColumns()} showPagination={false} />
        <br />
        <Button
          type="dashed"
          block
          onClick={() => {
            this.list.push({
              content: '',
              sort: '',
              unselectedTitleImg: '',
              titleImg: '',
              sliderPreviewUrl: '',
              itemJumpConfigCode: '',
            })
            this.$forceUpdate()
          }}
        >
          + 添加
        </Button>
      </div>
    )
  },
}
</script>
